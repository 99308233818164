export default function (Component) {
  const _Component = Component
  _Component.__i18n = _Component.__i18n || []
  _Component.__i18n.push({
    "locale": "",
    "resource": {
      "en-US": {
        "login__emailSignIn--title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already Have an Account? "])},
        "login__emailSignIn--subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in by email and we'll send you a verification link. You can also [sign in by mobile phone](/login/phone)"])},
        "account__email--label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "global__submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
      }
    }
  })
}
