<i18n>
[
    "login__emailSignIn--title",
    "login__emailSignIn--subtitle",
    "login__phoneJoinNow--cta",
    "account__email--label",
    "global__submit",
]
</i18n>

<template>
    <div class="c-email-login">
        <LoginContainer
            :title="$t('login__emailSignIn--title')"
            :subtitle="$t('login__emailSignIn--subtitle')"
            :onSubmit="onSubmit"
            :errorText="serviceErrorMessage"
            :isLoaded="isLoaded"
            :submitButton="{
                class: 'u-spacer--5',
                text: $t('global__submit'),
            }"
        >
            <template #form-field>
                <EmailInput
                    v-model="email"
                    :shouldAutoFocus="true"
                    :labelText="$t('account__email--label')"
                    name="email"
                    class="u-spacer--5"
                />
            </template>
            <template #after-form>
                <BaseMarkdown
                    :source="$t('login__phoneJoinNow--cta')"
                    class="o-text--caption u-text--gray"
                />
            </template>
        </LoginContainer>
    </div>
</template>

<script>

import { get } from 'lodash-es';
import { mapActions } from 'vuex';

import { LOGIN_MODULE_NAME, SEND_VERIFICATION_EMAIL } from '~coreRoutes/login/js/login-store';
import { genericRouteNames } from '~coreModules/core/js/router-constants';

import LoginContainer from '~coreRoutes/login/components/LoginContainer.vue';
import EmailInput from '~coreModules/core/components/ui/form-elements/text-inputs/EmailInput.vue';
import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';

export default {
    name: 'LoginEmailForm',
    components: {
        LoginContainer,
        EmailInput,
        BaseMarkdown,
    },
    data() {
        return {
            email: '',
            serviceErrorMessage: '',
            isLoaded: false,
        };
    },
    methods: {
        ...mapActions(LOGIN_MODULE_NAME, {
            sendVerificationEmail: SEND_VERIFICATION_EMAIL,
        }),
        async onSubmit() {
            return this.sendVerificationEmail(this.email)
                .then(() => {
                    this.isLoaded = true;
                    this.$router.push({ name: genericRouteNames.login.email.sent });
                })
                .catch((error) => {
                    this.serviceErrorMessage = get(error, 'data.message', '');
                });
        },
    },
};
</script>

<style lang="scss">

</style>
